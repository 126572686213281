import { KurzCartEntry, KurzDetailedCart, KurzDetailedCartEntry, KurzDetailedPrice } from '../kurz-components/shared/types/kurz-cart.interface';
import { KurzProductPresetQueryParams } from '../kurz-components/shared/types/kurz-product.interface';


export function getPDPUrlAndParams(entry: KurzCartEntry, preconfigured?: boolean): {url: string; params: KurzProductPresetQueryParams;} {

  const presetParams: KurzProductPresetQueryParams = {
    materialNumber: entry.product.code, // entry.product.code is material number
    coreCode: entry.coreSpecification?.code,
    finishingTypeCode: entry.finishingType?.code,
    priceType: entry.priceBaseInfo?.priceBaseType,
    width: (entry.foilWidth + ''),
    length: (entry.foilLength + ''),
    quantity: entry.quantity + '',
  };

  if (preconfigured) {

    if (entry.priceBaseInfo?.priceBaseType === 'CONTRACT') {
      presetParams.contractId = entry.priceBaseInfo.contractId;
      presetParams.contractEntryId = entry.priceBaseInfo.contractPosition;
      presetParams.contractName = entry.priceBaseAsOrdered;
    }

    if (entry.priceBaseInfo?.priceBaseType === 'PROJECT') {
      presetParams.projectName = entry.priceBaseAsOrdered;
    }

  }

  // create the url for the base product
  // note: entry.product.url is the url for the variant not the base product
  return {params: presetParams, url: `/p/${entry.product.baseProduct}`};

}

export interface ExtractedDiscountsAndSurchargesEntryObject {
  refEntry: KurzDetailedCartEntry;
  positionPrice: number;
  totalPrice: number;
  kurzDiscounts: KurzDetailedPrice[];
  discountsSum: number;
  kurzSurcharges: KurzDetailedPrice[];
  surchargesSum: number;
  currencyIso: string;
  detailsKnown: boolean;
}

export interface ExtractedDiscountsAndSurchargesCartObject {
  subTotalPrice: number;
  /**
   * can differ from "subTotalPrice" as it does not use the subTotal from the cart but
   * calculated the subtotal only by the use of the entries
   */
  calculatedSubTotalPrice: number;
  totalPrice: number;
  positionPriceSum: number;
  kurzDiscounts: KurzDetailedPrice[];
  discountsSum: number;
  kurzSurcharges: KurzDetailedPrice[];
  surchargesSum: number;
  currencyIso: string;
  entries: ExtractedDiscountsAndSurchargesEntryObject[];
  positionDetailsKnown: boolean;
  headDetailsKnown: boolean;
}

export function extractDiscountsAndSurchargesFromCart(cart: KurzDetailedCart) {

  const obj: ExtractedDiscountsAndSurchargesCartObject = {
    subTotalPrice: 0,
    calculatedSubTotalPrice: 0,
    positionPriceSum: 0,
    totalPrice: 0,
    kurzDiscounts: (cart.kurzDiscounts) || [],
    discountsSum: 0,
    kurzSurcharges: cart.kurzSurcharges || [],
    surchargesSum: 0,
    currencyIso: cart.totalPrice?.currencyIso,
    entries: [],
    positionDetailsKnown: false,
    headDetailsKnown: false
  };

  obj.totalPrice = cart.totalPrice?.value || 0;
  obj.subTotalPrice = cart.subTotal?.value;
  obj.discountsSum = 0;
  obj.surchargesSum = 0;

  obj.kurzDiscounts.forEach(discount => {
    const value = (discount?.totalValue?.value || 0);
    obj.discountsSum += value;
  });

  obj.kurzSurcharges.forEach(surcharge => {
    const value = (surcharge?.totalValue?.value || 0);
    obj.surchargesSum += value;
  });

  obj.entries = cart.entries.map(entry => {
    const thisObj = extractDiscountsAndSurchargesFromEntry(entry);
    obj.calculatedSubTotalPrice += thisObj.totalPrice;
    obj.positionPriceSum += thisObj.positionPrice;
    return thisObj;
  });

  obj.positionDetailsKnown = obj.entries.some(entry => (entry.detailsKnown));
  obj.headDetailsKnown = (obj.kurzDiscounts.length > 0 || obj.kurzSurcharges.length > 0);

  return obj;

}

export function extractDiscountsAndSurchargesFromEntry(entry: KurzDetailedCartEntry) {

  const obj: ExtractedDiscountsAndSurchargesEntryObject = {
    refEntry: entry,
    positionPrice: 0,
    totalPrice: 0,
    kurzDiscounts: (entry.kurzDiscounts) || [],
    discountsSum: 0,
    kurzSurcharges: entry.kurzSurcharges || [],
    surchargesSum: 0,
    currencyIso: entry.totalPrice?.currencyIso,
    detailsKnown: false
  };

  obj.totalPrice = entry.totalPrice?.value || 0;
  obj.positionPrice = obj.totalPrice;
  obj.discountsSum = 0;
  obj.surchargesSum = 0;

  obj.kurzDiscounts.forEach(discount => {
    const value = (discount?.totalValue?.value || 0);
    obj.positionPrice -= value;
    obj.discountsSum += value;
  });

  obj.kurzSurcharges.forEach(surcharge => {
    const value = (surcharge?.totalValue?.value || 0);
    obj.positionPrice -= value;
    obj.surchargesSum += value;
  });

  obj.positionPrice = Math.round(obj.positionPrice * 100) / 100;

  obj.detailsKnown = (obj.kurzDiscounts.length > 0 || obj.kurzSurcharges.length > 0);

  return obj;

}
