import { inject, Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { roundFractionalDigits } from '@util/functions/numbers';
import { UtilStringHelper } from '@util/functions/strings';
import { BehaviorSubject } from 'rxjs';
import { extractDiscountsAndSurchargesFromCart, extractDiscountsAndSurchargesFromEntry } from '../functions/kurz-cart-entry.functions';
import { KurzReorderResponseData } from '../kurz-components/kurz-reorder/kurz-reorder.types';
import { KurzCartEntry, KurzDetailedCart, KurzDetailedCartEntry } from '../kurz-components/shared/types/kurz-cart.interface';
import { KurzProduct } from '../kurz-components/shared/types/kurz-product.interface';
import { KurzCartModificationResponseData } from './kurz-cart.service';
import { KurzUnitService } from './kurz-unit.service';
import { deepCopy } from '@util/functions/objects';

export type TSDownloadElementClasses = 'document-info-button' | '”document-info-link' | 'product-info-link' | 'own-configuration-info-link' | string;


export interface GoogleTagManagerEcommerceEvent {
  eventType: 'add_to_cart' | 'remove_from_cart' | 'begin_checkout' | 'purchase' | 'view_item';
  ecommerce: GoogleTagManagerEcommerceObject;
}

type MKMCookieBannerWindow = Window & {
  document: {
    cookieBanner: {
      show: () => void;
      hide: () => void;
      consent: {
        analytics: boolean;
        essential: boolean;
        marketing: boolean;
        optionalFunctionality: boolean;
      };
    }
  };
};

export type MKMCookieBannerConsentObject = {
  source: 'cookie' | 'banner';
  consent: MKMCookieBannerWindow['document']['cookieBanner']['consent'];
};

/**
 * Interface for an ecommerce object
 * Ver 0.1.4
 * using: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag
 */
export interface GoogleTagManagerEcommerceObject {
  /**
   * required for "purchase" event
   * here: hybris order number: FE receives this after successful placeOrder
   */
  transaction_id?: string;
  /**
   * accumulated value of all global discounts and surcharges
   */
  global_discount?: number;
  /**
   * ISO 4217 (EUR, USD, CAD...)
   */
  currency: string;
  /**
   * actual cart value (cart.subTotalPrice.value)
   */
  value: number;
  /**
   * all items concerning this events (e.q. add_to_cart: added materials)
   */
  items: Array<{
    baseProductCode?: string;
    /**
     * material code // from that they can refer if it is a roll or a jumbo
     */
    item_id?: string;
    /**
     * product name
     */
    item_name: string;
    /**
     * On which page, the user emitted the event
     * Note: Originally on which list the user interacted with the product lastly But this information is not tracked nor saved.
     * 'item_list_name' only for 'view_item' and 'add_to_cart'
     */
    item_list_name?: GTMEcommerceEventOrigin;
    /**
     * price per piece
     */
    price?: number;
    /**
     * string like from KurzUnitService.getFormattedLength() - "122 m"
     */
    length?: string;
    /**
     * string like from KurzUnitService.getFormattedWidth() - "1000 mm"
     */
    width?: string;
    /**
     * string with: "{{code}} - {{name}}"
     */
    core?: string;
    /**
     * string with: "{{code}} - {{name}}"
     */
    finishing_type?: string;
    /**
     * item's quantity
     */
    quantity?: number;
    /**
     * the currency, if it deviates from globally mentioned currency
     */
    currency?: string;
  }>;
}

/**
 * All 3 are from type "KurzCartEntry" but the BE delivers inconsistent data
 */
export type EcommerceEntry = KurzCartEntry | KurzCartModificationResponseData['entry'] | KurzReorderResponseData['entries'][0];

export type GTMEcommerceEventOrigin = 'quick-order' | 'stock-sale' | 'product-details-page' | 'saved-carts' | 'reorder' | 'unknown';

@Injectable({
  providedIn: 'root'
})
export class CookieBannerAndGtmService {

  private windowRef = inject(WindowRef);
  private kurzUnitService = inject(KurzUnitService);

  private gtmEventsBehaviorSubject = new BehaviorSubject<any>(null);

  gtmEvent$ = this.gtmEventsBehaviorSubject.asObservable();

  showMKMBanner() {
    (this.windowRef.nativeWindow as MKMCookieBannerWindow).document.cookieBanner.show();
  }

  hideMKMBanner() {
    (this.windowRef.nativeWindow as MKMCookieBannerWindow).document.cookieBanner.hide();
  }

  getMKMBannerConsentObject(): MKMCookieBannerConsentObject {
    let consent = (this.windowRef.nativeWindow as MKMCookieBannerWindow).document.cookieBanner?.consent;
    let source: 'cookie' | 'banner' = 'banner';

    if (!consent) {
      const cookieValue = UtilStringHelper.getCookieValue('mkm_cbconsent') || '{}';
      consent = JSON.parse(cookieValue)?.['consents'];
      source = 'cookie';
    }

    return consent ? {consent, source} : null;
  }

  addGTMEvent(eventType: string, eventOptions: Record<string, any>) {
    this.windowRef.nativeWindow['dataLayer'] ||= [];

    const e = {
      event: eventType,
      ...eventOptions
    };

    this.windowRef.nativeWindow['dataLayer'].push(e);
    this.gtmEventsBehaviorSubject.next(e);
  }

  addPageViewEvent(pageUrl: string, pageTitle: string, previousUrl?: string) {
    this.addGTMEvent('virtualPageview', {pageUrl, pageTitle, previousUrl});
  }

  addSignUpEvent(buttonLabel: string) {
    this.addGTMEvent('signUp', {textContent: buttonLabel, elementId: 'request-access-form'});
  }

  addTechnicalSpecificationDownloadEvent(baseProductCode: string, fileName: string, elementClasses: TSDownloadElementClasses = '') {
    this.addGTMEvent('technicalSpecificationDownload', {baseProductCode, fileName, 'gtm.elementClasses': elementClasses});
  }

  addInvoiceDownloadEvent(buttonLabel: string, fileName: string) {
    this.addGTMEvent('invoiceDownload', {textContent: buttonLabel, fileName});
  }

  addEcommerceEvent(evenType: GoogleTagManagerEcommerceEvent['eventType'], ecommerceObj: GoogleTagManagerEcommerceEvent['ecommerce']) {
    this.checkIfDataLayerNeedsCleanPipe();
    this.addGTMEvent(evenType, {ecommerce: ecommerceObj});
  }


  /**
   * event triggered if the user logs in
   * @param buttonText - label of the login button at the time of clicking
   * @param formElementId - the id of the form element
   */
  addLoginEvent(buttonText: string, formElementId: string) {
    const eventOptions = {
      textContent: buttonText,
      elementId: formElementId,
    };
    this.addGTMEvent('shopLogin', eventOptions);
  }

  private checkIfDataLayerNeedsCleanPipe() {

    const lasItem = (this.windowRef.nativeWindow['dataLayer'] as any[])?.at(-1);

    if (lasItem?.ecommerce !== null) {
      this.windowRef.nativeWindow['dataLayer']?.push({ecommerce: null});
    }
  }

  getEcommerceObjectFromCart(cart: KurzDetailedCart, eventType: GoogleTagManagerEcommerceEvent['eventType'], eventOrigin: GTMEcommerceEventOrigin): GoogleTagManagerEcommerceEvent['ecommerce'] {
    const discountSurchargesObj = extractDiscountsAndSurchargesFromCart(cart);
    const obj = this.getEcommerceObjectFromEntries(cart.entries, eventType as any, eventOrigin, 'cartEntry');
    if ((eventType === 'begin_checkout' || eventType === 'purchase')) {
      // sub total price - not discounted price
      obj.value = discountSurchargesObj.subTotalPrice;
      // if sub total price is not the correct one then...
      // this: // const positionPriceMultipliedByQuantitySum = obj.items.reduce((prev, cur) => (prev + cur.price * cur.quantity), 0);

      obj.global_discount = discountSurchargesObj.discountsSum - discountSurchargesObj.surchargesSum;
      obj.currency = discountSurchargesObj.currencyIso;
    }

    return obj;
  }

  getEcommerceObjectItem(
    entry: EcommerceEntry,
    evenType: Exclude<GoogleTagManagerEcommerceEvent['eventType'], 'view_item'>,
    eventOrigin: GTMEcommerceEventOrigin,
    treatEntryAs: 'cartEntry' | 'reorderResponseEntry' | 'cartModificationResponseEntry' | 'unknown' = 'unknown'
  ): GoogleTagManagerEcommerceEvent['ecommerce']['items'][0] {

    /**
     * note: only after the visit of the cart page, can we trust all currently applied discounts and surcharges to be correct.
     */
    const discountSurchargesObj = extractDiscountsAndSurchargesFromEntry(entry as KurzDetailedCartEntry);
    const itemPrice = discountSurchargesObj.positionPrice;

    /**
     * NOTE: the BE does not have a consistent data model - therefore the FE has to compensate
     * maybe by the use of "treatEntryAs"
     */
    const baseProductNumber = entry.product.baseProductCode || entry.product.baseProduct || entry.product?.code;

    const item: GoogleTagManagerEcommerceEvent['ecommerce']['items'][0] = {
      baseProductCode: baseProductNumber,
      item_id:  entry.product?.code || '',
      item_name: entry.product.name,
      price: roundFractionalDigits((itemPrice / (entry.quantity ?? 1)), 2),
      quantity: entry.quantity || 1
    };

    if (entry.priceBaseInfo?.currency?.isocode) {
      item.currency = entry.priceBaseInfo.currency.isocode;
    }

    if (evenType === 'add_to_cart') {
      item.item_list_name = eventOrigin;
    }

    if (entry.coreSpecification) {
      const cSp = entry.coreSpecification;
      item.core = (cSp.code && cSp.name) ? `${cSp.code} - ${cSp.name}` : (cSp.code + '');
    }

    if (entry.finishingType) {
      const ftSp = entry.finishingType;
      item.finishing_type = (ftSp.code && ftSp.name) ? `${ftSp.code} - ${ftSp.name}` : (ftSp.code + '');
    }

    if (entry.foilLength && entry.priceBaseInfo?.unit) {
      const unitSystem = (['MMM', 'M2'].includes(entry.priceBaseInfo.unit)) ? 'm' : 'ft';
      item.length = this.kurzUnitService.getFormattedLength(entry.foilLength, {explicitUnitSystem: unitSystem});
    }

    if (entry.foilWidth && entry.priceBaseInfo?.unit) {
      const unitSystem = (['MMM', 'M2'].includes(entry.priceBaseInfo.unit)) ? 'mm' : 'in';
      item.width = this.kurzUnitService.getFormattedWidth(entry.foilWidth, {explicitUnitSystem: unitSystem});
    }

    return item;
  }

  getEcommerceObjectFromEntries(entries: EcommerceEntry[], eventType: Exclude<GoogleTagManagerEcommerceEvent['eventType'], 'view_item'>, eventOrigin: GTMEcommerceEventOrigin, treatEntryAs: 'cartEntry' | 'reorderResponseEntry' | 'cartModificationResponseEntry' | 'unknown' = 'unknown'): GoogleTagManagerEcommerceEvent['ecommerce'] {

    if (!entries || entries.length === 0) {
      return null;
    }

    const currency = entries[0].priceBaseInfo?.currency.isocode || entries[0].totalPrice?.currencyIso;

    const obj: GoogleTagManagerEcommerceEvent['ecommerce'] = {
      currency,
      value: 0, // calculated in a loop through entries
      items: []
    };

    const items = entries.map(entry => {

      const item = this.getEcommerceObjectItem(entry, eventType, eventOrigin, treatEntryAs);
      obj.value += item.price * item.quantity;
      return item;

    });

    obj.items = items;
    obj.value = roundFractionalDigits(obj.value, 2);

    return obj;
  }

  getEcommerceObjectForViewItem(product: KurzProduct): GoogleTagManagerEcommerceEvent['ecommerce'] {

    const obj: GoogleTagManagerEcommerceObject = {
      currency: product.price?.currencyIso,
      value: product.price?.value,
      items: [{
        baseProductCode: product.code,
        item_name: product.name,
        item_list_name: 'product-details-page',
        price: product.price?.value,
      }]
    };

    return obj;
  }

  getDiffEcommerceEventForQuantityUpdate(oldEcom: GoogleTagManagerEcommerceEvent['ecommerce'], newEcom: GoogleTagManagerEcommerceEvent['ecommerce']): GoogleTagManagerEcommerceEvent {

    if (oldEcom.items?.length !== 1 || newEcom.items?.length !== 1) {
      console.warn('Quantity update was not tracked by GTM', {oldEcommerceObject: oldEcom, newEcommerceObject: newEcom});
      return {
        ecommerce: null,
        eventType: null
      };
    }

    let eventType: GoogleTagManagerEcommerceEvent['eventType'] = null;

    if (newEcom.items[0].quantity > oldEcom.items[0].quantity) {
      eventType = 'add_to_cart';
    }

    if (newEcom.items[0].quantity < oldEcom.items[0].quantity) {
      eventType = 'remove_from_cart';
    }

    if (!eventType) {
      console.warn('No quantity difference', {oldEcommerceObject: oldEcom, newEcommerceObject: newEcom});
      return {
        ecommerce: null,
        eventType
      };
    }

    let diffEcom: GoogleTagManagerEcommerceEvent['ecommerce'] = deepCopy(newEcom);

    if (eventType === 'add_to_cart') {
      diffEcom.items[0].quantity = newEcom.items[0].quantity - oldEcom.items[0].quantity;
      diffEcom.value = newEcom.value - oldEcom.value;
    } else {
      diffEcom.items[0].quantity = oldEcom.items[0].quantity - newEcom.items[0].quantity;
      diffEcom.value = oldEcom.value - newEcom.value;
    }

    diffEcom.value = roundFractionalDigits(diffEcom.value, 2);

    return {
      ecommerce: diffEcom,
      eventType
    };
  }

}
